import HSHeader from '../front-ui/vendor/hs-header/src/js/hs-header'
import HSMegaMenu from '../front-ui/vendor/hs-mega-menu/src/js/hs-mega-menu'
import HSUnfold from '../front-ui/vendor/hs-unfold/src/js/hs-unfold'
import '../front-ui/js/hs.core'
import '../front-ui/js/hs.validation'
import 'jquery-validation'
import HSShowAnimation from '../front-ui/vendor/hs-show-animation/src/js/hs-show-animation'
import HSCounter from '../front-ui/vendor/hs-counter/src/js/hs-counter'
import HSStickyBlock from '../front-ui/vendor/hs-sticky-block/src/js/hs-sticky-block'
import AOS from 'aos'
import HSGoTo from '../front-ui/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '../front-ui/vendor/hs-video-player/src/js/hs-video-player'
import '../front-ui/vendor/slick-carousel/slick/slick'
import '../front-ui/js/hs.slick-carousel'
import Typed from '../front-ui/vendor/typed.js/lib/typed'
//Needs Implemenation
import '../front-ui/vendor/select2/dist/js/select2.full'
import '../front-ui/js/hs.select2'

$(document).on('ready', function () {

    // initialization of header
    var header = new HSHeader($('#header')).init();

    // initialization of mega menu
    var megaMenu = new HSMegaMenu($('.js-mega-menu'), {
        desktop: {
          position: 'left'
        }
      }).init();

    // initialization of unfold
    var unfold = new HSUnfold('.js-hs-unfold-invoker').init();

    // initialization of text animation (typing)
    if($(".js-text-animation")[0]){
        var typed = new Typed(".js-text-animation", {
            strings: ["Sales.", "Service.", "Marketing."],
            typeSpeed: 140,
            loop: true,
            backSpeed: 65,
            backDelay: 1600
        });
    }
    
    // initialization of form validation
    $('.js-validate').each(function() {
       
        var validation = $.HSCore.components.HSValidation.init($(this), {
            rules: {
                inf_field_Phone1: {
                    required: true,
                    digits: true,
                    rangelength: [10, 10],
                },
            }
        });            
    });

    // initialization of select picker ( Needs Impelmentation, Will not accept other classes, or work with j-query validate)
    $('.js-custom-select').each(function () {
        var select2 = $.HSCore.components.HSSelect2.init($(this));
    });
    
    // initialization of slick carousel
    $('.js-slick-carousel').each(function() {
        var slickCarousel = $.HSCore.components.HSSlickCarousel.init($(this));
    });
    
    // initialization of video player
    $('.js-inline-video-player').each(function () {
        var videoPlayer = new HSVideoPlayer($(this)).init();
    });

    // initialization of show animations
    $('.js-animation-link').each(function () {
    var showAnimation = new HSShowAnimation($(this)).init();
    });

    // initialization of counter
    $('.js-counter').each(function() {
    var counter = new HSCounter($(this)).init();
    });

    //Side Bar
    $('.js-sticky-block').each(function () {
        var stickyBlock = new HSStickyBlock($(this)).init();
    });

    // initialization of go to
    $('.js-go-to').each(function () {
    var goTo = new HSGoTo($(this)).init();
    });

    // Scroll to (Section)
    $(function () {
        $('a[href*=#scroll-to-]:not([href=#scroll-to-])').on('click', function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 10
                    }, 1000);
                    return false;
                }
            }
        });
    });

    // initialization of aos for sidebar tabs
    AOS.init({
        duration: 550,
        once: true
    });

});


